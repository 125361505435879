import React from "react"

import { withStyles, Paper, Divider } from "@material-ui/core"
import { BusinessCenter, HeadsetMic, LocalLibrary } from "@material-ui/icons"
import Container from "../components/Container"
import ImgSection from "../components/ImgSection"
import Image from "../images/laboratory.jpg"
import ImgMission from "../images/mission.jpg"
import AboutOverlay from "../images/about_overlay.png"

const IndexPage = React.memo(({ classes, location }) => {
  if (location.pathname === "/offline-plugin-app-shell-fallback/") return null
  return (
    <Container title="Início" page={0}>
      <div className={classes.root}>
        <div className={classes.banner}>
          <img
            src={Image}
            className={classes.img}
            alt="Imagem de uma mulher em um laboratório"
          />
          <div className={classes.container}>
            <h1 className={classes.mainText}>
              Estamos situados em Recife e atuando a mais de 15 anos no Mercado
              de Calibração e Equipamentos para Laboratório.
            </h1>
            <h3 className={classes.subMainText}>
              Referência na Região Nordeste, atendendo os principais
              Laboratórios de Recife, Salvador, Natal, Fortaleza, João Pessoa,
              Maceió, Teresina e São Luiz.
            </h3>
          </div>
        </div>
        <div className={classes.paperContainer}>
          <div className={classes.paperDiv}>
            <Paper className={classes.paper} elevation={5}>
              <div className={classes.grid}>
                <h3>
                  <BusinessCenter /> Oferecemos:
                </h3>
                <p>
                  Calibrações Rastreáveis de acordo com a norma ISO IEC 17025 e
                  Padrões Rastreáveis a RBC (Rede Brasileira de Calibração)
                </p>
              </div>
              <Divider className={classes.divider} variant="middle" />
              <div className={classes.grid}>
                <h3>
                  <HeadsetMic />
                  Atendemos:
                </h3>
                <p>
                  Laboratórios de análises clínicas, pesquisas, tecnologia e
                  indústrias.
                </p>
              </div>
              <Divider className={classes.divider} variant="middle" />
              <div className={classes.grid}>
                <h3>
                  <LocalLibrary />
                  Especializados:
                </h3>
                <p>
                  Calibração em Massa, temperatura, umidade, rotação, densidade,
                  volume, PH, condutividade e viscosidade.
                </p>
              </div>
            </Paper>
          </div>
        </div>
      </div>
      <ImgSection
        img={ImgMission}
        title="Nossa Missão:"
        text="Ser reconhecido pela excelência na Prestação do Serviço, ser referência naquilo que nos propomos e consequentemente ser Destaque no ramo de atuação."
      />
    </Container>
  )
})

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
  },
  banner: {
    height: "75vh",
    position: "relative",
    overflow: "hidden",
  },
  divider: {
    height: "100%",
    width: 1,
  },
  mainText: {
    position: "absolute",
    padding: 5,
    textAlign: "center",
    color: "white",
    top: "30%",
  },
  subMainText: {
    position: "absolute",
    padding: 5,
    textAlign: "center",
    color: "white",
    bottom: "20%",
  },
  img: {
    filter: "brightness(0.5)",
    position: "absolute",
    display: "block",
    width: "100vw",
    height: " 100%",
    objectFit: "cover",
    zIndex: -100,
  },
  missionSection: {
    position: "relative",
    zIndex: 99,
    backgroundImage: `url(${AboutOverlay})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    marginTop: 170,
    padding: 70,
  },
  missionContainer: {
    position: "relative",
    width: "100%",
    paddingRight: 15,
    paddingLeft: 15,
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: 1470,
  },
  mission: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: -20,
    marginLeft: -20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  missionImg: {
    position: "relative",
    width: "100%",
    paddingRight: 15,
    paddingLeft: 15,
    flex: "0 0 50%",
    maxWidth: "50%",
    overflow: "hidden",
    "& > img": {
      verticalAlign: "middle",
      borderStyle: "none",
      width: "100%",
    },
  },
  missionText: {
    flex: "0 0 41.666667%",
    maxWidth: "41.666667%",
    paddingLeft: 10,
    "& > h2": {
      color: "#005251",
      fontWeight: 600,
    },
  },
  paperContainer: {
    position: "relative",
    width: "100%",
  },
  paperDiv: {
    width: "100%",
    position: "absolute",
    zIndex: 99,
    bottom: "15%",
    transform: "translateY(60%)",
    display: "flex",
    justifyContent: "center",
    margin: "10px 0px",
  },
  paper: {
    display: "flex",
    margin: "0px 10%",
  },
  grid: {
    flexGrow: 1,
    padding: 10,
    maxWidth: "33.333333%",
    flexBasis: "33.333333%",
    "& > h3": {
      alignContent: "center",
      display: "flex",
      margin: 0,
      "& > svg": {
        paddingRight: 10,
        fill: "#005251",
      },
    },
  },
  "@media (max-width: 1400px)": {
    paper: {
      margin: "0px 5%",
    },
  },
  "@media only screen and (max-width: 1200px)": {
    missionSection: {
      marginTop: 100,
      backgroundImage: "none",
    },
    missionContainer: {
      maxWidth: 970,
    },
  },
  "@media (max-width: 900px)": {
    paper: {
      margin: "0px 5%",
    },
  },
  "@media (max-width: 800px)": {
    subMainText: {
      bottom: "10%",
    },
    paper: {
      margin: "0px 5%",
      flexDirection: "column",
    },
    banner: {
      height: "60vh",
    },
    paperDiv: {
      transform: "inherit",
      position: "relative",
    },
    grid: {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    divider: {
      height: 1,
      width: "100%",
      margin: 0,
    },
    missionSection: {
      paddingTop: 20,
      padding: 0,
      marginTop: 10,
      display: "flex",
      backgroundImage: `url(${AboutOverlay})`,
      overflow: "hidden",
    },
    mission: {
      flexDirection: "column",
    },
    missionImg: {
      maxWidth: "100%",
    },
    missionText: {
      maxWidth: "100%",
      padding: "0px 20px",
    },
  },
  "@media (max-width: 500px)": {
    banner: {
      height: "calc(100vh + 200px)",
    },
  },
}

export default withStyles(styles)(IndexPage)
